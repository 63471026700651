import request from "../utils/request"

export function queryPhone(data) {
    return request({
      url: '/platform/eccrm.clerk/get_client_by_mobile',
      method: 'post',
      data,
    })
}

export function submitData(data) {
  return request({
    url: '/platform/eccrm.clerk/turnover_data',
    method: 'post',
    data,
  })
}
