<template>
  <div class="content" style="margin-top: 20px">
    <el-descriptions
      class="margin-top"
      title="客户信息"
      :column="1"
      border
      labelClassName="label"
    >
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          签单时间
        </template>
        {{ info.belong_time }}
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user-solid"></i>
          客户经理
        </template>
        {{ info.clerk_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          客户名称
        </template>
        {{ info.client_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          客户手机号
        </template>
        {{ info.client_mobile }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          客户居住地
        </template>
        {{ info.city }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-attract"></i>
          成交金额
        </template>
        {{ info.clinch_money }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          客户联系地址
        </template>
        {{ info.province + info.city + info.region + info.client_address }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-notebook-2"></i>
          成交产品
        </template>
        {{ info.clinch_products }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-time"></i>
          创建时间
        </template>
        {{ info.create_time }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-picture-outline"></i>
          签单喜报
        </template>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="6">
            <img :src="img_url" alt="签单喜报" width="100%" />
          </el-col>
        </el-row>
      </el-descriptions-item>
    </el-descriptions>
    <div style="margin-top: 20px">
      <el-button type="primary" icon="el-icon-download" @click="onGenImg()"
        >下载战报</el-button
      >
      <el-button type="info" @click="onList()">返回列表</el-button>
    </div>
  </div>
</template>

<script>
import { getInfo } from "../api/detail";
import { getImage } from "../api/image";

export default {
  name: "Detail",
  components: {},
  data() {
    return {
      info: {},
      img_url: "",
    };
  },
  created() {
    let token = this.$store.state.token;
    if (!this.$route.query.turn_id && !token) return;
    getInfo({ ec_clerk_token: token, turn_id: this.$route.query.turn_id }).then(
      (res) => {
        this.info = res.data;

        getImage({ turn_id: this.info.turn_id }).then((res) => {
          if (res.code == 0) {
            this.img_url = res.data.file_path;
          }
        });
      }
    );
  },
  methods: {
    // 返回列表
    onList() {
      this.$router.replace({ name: "List" });
    },

    onGenImg() {
      getImage({ turn_id: this.info.turn_id }).then((res) => {
        if (res.code == 0) {
          this.img_url = res.data.file_path;
          window.open(res.data.file_path, "_blank");
        }
      });
    },
  },
};
</script>

<style>
.label {
  width: 20%;
}
</style>
