<template>
  <div class="content">
    <el-form
      ref="form"
      label-width="100px"
      :model="formLabelAlign"
      :rules="formRules"
      style="margin-top: 20px"
    >
      <el-form-item label="客户电话" prop="phone">
        <el-input
          v-model="formLabelAlign.phone"
          clearable
          @change="onQueryPhone"
          placeholder="请输入客户电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="客户姓名" prop="name">
        <el-input
          v-model="formLabelAlign.name"
          clearable
          placeholder="请输入客户姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="region">
        <el-cascader
          class="cascader"
          v-model="formLabelAlign.region"
          :options="areaData"
          @change="handleChange"
          size="medium"
          :props="cascaderProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input
          v-model="formLabelAlign.address"
          clearable
          placeholder="请输入详细地址 例如:xxx街道"
        ></el-input>
      </el-form-item>
      <el-form-item label="订单金额" prop="money">
        <el-input
          type="number"
          v-model="formLabelAlign.money"
          clearable
          placeholder="请输入订单金额"
        ></el-input>
      </el-form-item>
      <el-form-item label="成交产品" prop="product">
        <el-input
          type="textarea"
          rows="3"
          v-model="formLabelAlign.product"
          clearable
          placeholder="请输入成交产品"
        ></el-input>
      </el-form-item>

      <el-form-item label="成交日期" prop="time">
        <el-date-picker
          v-model="formLabelAlign.time"
          type="date"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onBack()" v-if="clerk_id">上一步</el-button>
        <el-button type="primary" @click="submitForm('form')">提交</el-button>
        <el-button type="info" @click="onList()">返回列表</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// @ is an alias to /src
import { queryPhone, submitData } from "../api/index";
import areaData from "../../public/area_format_user.json";

import axios from "axios";

// const form = {
//   phone: "",
//   name: "",
//   region: [],
//   address: "",
//   money: "",
//   product: "",
//   time: "",
// };

var validatePhone = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入手机号"));
  } else {
    let str = value.replace(/\s+/g, "");
    if (!/^1(3|4|5|7|8)\d{9}$/.test(str)) {
      callback(new Error("手机号码有误，请重填"));
    }
    callback();
  }
};

var validateRegion = (rule, value, callback) => {
  if (value.length == 0) {
    callback(new Error("请选择地址"));
  } else {
    callback();
  }
};

const formRules = {
  phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
  name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
  region: [{ required: true, validator: validateRegion, trigger: "blur" }],
  address: [{ required: true, message: "详细地址不能为空", trigger: "blur" }],
  money: [{ required: true, message: "金额不能为空", trigger: "blur" }],
  product: [{ required: true, message: "成交商品不能为空", trigger: "blur" }],
  time: [{ required: true, message: "成交日期不能为空", trigger: "blur" }],
};

const cascaderProps = {
  value: "name",
  label: "name",
  children: "childs",
};

export default {
  name: "Home",
  components: {},
  data() {
    return {
      formLabelAlign: {
        phone: "",
        name: "",
        region: [],
        address: "",
        money: "",
        product: "",
        time: "",
      },
      formRules: formRules,
      options: [],
      //  地址组件prop配置
      areaData,
      cascaderProps,
      clerk_id:'',
      client_id:'',
    };
  },
  created() {
    // this.getAddress();
    if(this.$route.params.clerk_id){
      this.clerk_id = this.$route.params.clerk_id
    }
  },
  methods: {
    // 提交表单
    submitForm(formName) {
      const _this = this;

      _this.$refs[formName].validate((valid) => {
        if (valid) {
          let formdata = _this.formLabelAlign,
            country = "",
            time = "";

          if (formdata.time) {
            time = this.Format("YYYY-mm-dd", new Date(formdata.time));
          }

          if (formdata.region[0] != "国外") {
            country = "中国";
          } else {
            country = "国外";
          }

          let data = {
            ec_clerk_token:_this.$store.state.token,
            clerk_id: _this.clerk_id,
            client_id:_this.client_id,
            client_name: formdata.name,
            client_mobile: formdata.phone,
            country: country,
            province: formdata.region[0],
            city: formdata.region[1],
            region: formdata.region[2],
            client_address: formdata.address,
            clinch_money: formdata.money,
            clinch_products: formdata.product,
            belong_time: time,
          };
          if (this.$store.state.token) {
            submitData(data).then((res) => {
              if (res.code == 0) {
                _this.formLabelAlign = {
                  phone: "",
                  name: "",
                  region: [],
                  address: "",
                  money: "",
                  product: "",
                  time: "",
                };
                _this.$router.replace({
                  name: "Image",
                  query: { turn_id: res.data.turn_id },
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 选择地址
    handleChange(value) {
      console.log(value);
    },
    // 手机号查询
    onQueryPhone(value) {
      const _this = this;
      let mobile = value.replace(/\s+/g, "");
      queryPhone({ mobile }).then((res) => {
        const data = res.data;
        _this.client_id = data.user_id || ''
        _this.formLabelAlign.name = data.realName
          ? data.realName
          : data.nickName;
        _this.formLabelAlign.address = data.address;
        if (data.province && data.city && data.region) {
          _this.formLabelAlign.region = [
            data.province.replace("省", ""),
            data.city.replace("市", ""),
            data.region.replace("县", ""),
          ];
        }
      });
    },
    // 返回列表
    onList() {
      this.$router.replace({ name: "List" });
    },
    onBack(){
      this.$router.replace({ name: "Chooseid" });
    },

    Format(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },

    getAddress() {
      const app = this;

      axios
        .get("https://apis.map.qq.com/ws/district/v1/list?key=EUTBZ-KY2LS-GH5OL-6XJQM-DZC5J-6JBPI")
        .then((res) => {
            let list = [],
              obj = {};

            res.data.result.forEach((arr, index) => {
              arr.forEach((item) => {
                //let res = index == 0 ? list : obj[item.id.substr(0, index * 2)].children
                let data =
                  index == 0
                    ? list
                    : obj[item.id.substr(0, index * 2)].children ||
                      (obj[item.id.substr(0, index * 2)].children = []);
                data.push(
                  (obj[item.id.substr(0, index * 2 + 2)] = {
                    value: item.id,
                    label: item.fullname,
                    //children: []
                  })
                );
              });
            });
            obj = null;
            app.options = list;
        });
    },
  },
};
</script>

<style  scoped>
.cascader {
  width: 250px;
}
</style>

