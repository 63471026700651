<template>
  <div class="content">
    <el-form
      ref="form"
      label-width="100px"
      :model="formLabelAlign"
      style="margin-top: 20px"
    >
      <el-form-item label="选择门店" prop="name">
        <el-input
          v-model="formLabelAlign.shop_name"
          clearable
          placeholder="请输入选择门店"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="选择店员"
        prop="region"
        :rules="{
          required: true,
          message: '店员不能为空',
          trigger: 'blur',
        }"
      >
        <el-select v-model="formLabelAlign.shop_user" placeholder="请选择">
          <el-option
            v-for="item in clerk_list"
            :key="item.account_id"
            :label="item.accountuser.real_name"
            :value="item.clerk_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onNext()">下一步</el-button>
        <el-button type="info" @click="onBack()">返回列表</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import { getRole } from "../api/list";

export default {
  data() {
    return {
      formLabelAlign: {
        shop_name: "杭州直营店",
        shop_user: "",
      },
      clerk_list: [],
      // token
      token: "",
    };
  },
  created() {
    this.token = this.$store.state.token;
    let clerk_list = JSON.parse(localStorage.getItem("clerk_list"));

    if (clerk_list && clerk_list.length > 0) {
      this.clerk_list = clerk_list;
    } else {
      this.getClerkList();
    }
  },
  methods: {
    onNext() {
      if (this.formLabelAlign.shop_user) {
        this.$router.push({ name: "Index" ,params:{clerk_id:this.formLabelAlign.shop_user}});
      } else {
        this.$message.error('请选择店员');
        return;
      }
    },
    onBack() {
      this.$router.push({ name: "List" });
    },

    getClerkList() {
      getRole({ ec_clerk_token: this.token }).then((res) => {
        this.clerk_list = res.data.clerk_list.filter(item=>{
            if (item.accountuser) {
              return item
            }
        })
        localStorage.setItem("clerk_list", JSON.stringify(this.clerk_list));
      });
    },
  },
};
</script>
<style>
</style>