<template>
  <div class="content" style="margin-top: 20px">

    <div class="operation">
      <el-button type="primary" icon="el-icon-download" @click="onGenImg()"
        >下载战报</el-button
      >
      <el-button type="info" @click="onList()">返回列表</el-button>
    </div>
    


    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="10">
        <img :src="url" alt="签单喜报" width="100%"/>
      </el-col>
    </el-row>
   

    
  </div>
</template>
<script>
import { getImage } from "../api/image";

export default {
  data() {
    return {
      url: "",
    };
  },
  created() {
    let id = this.$route.query.turn_id;
    console.log(id);
    if (id) {
      this.getImgSrc(id);
    }
  },
  methods: {
    getImgSrc(turn_id) {
      getImage({ turn_id }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.url = res.data.file_path;
        }
      });
    },

    // 返回列表
    onList() {
      this.$router.replace({ name: "List" });
    },

    onGenImg() {
      window.open(this.url, "_blank");
    },
  },
};
</script>

<style scoped>

.operation {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
</style>